import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import ShowDialog from '@/components/ShowDialog.vue';
import { atHomeStore } from '@/store';
import JaasMeet from '@/components/JaasMeet.vue';
import UploadDownloadFileButton from '@/components/UploadDownloadFileButton.vue';
import DataTable from '../pedagogico/DataTable.vue';
let Details = class Details extends Vue {
    constructor() {
        super(...arguments);
        this.turmaAlunoId = null;
        this.items = [];
        this.loading = true;
        this.search = '';
        this.headers = [
            { align: 'center', text: 'Data da atividade', value: 'atividade_data_executada' },
            { align: 'center', text: 'Tarefas/revisões', value: 'atividade_descricao' },
            { align: 'center', text: 'Tarefa', value: 'bucket_arquivo_atividade_id' },
            { align: 'center', text: 'Correção', value: 'bucket_arquivo_correcao_id' },
        ];
    }
    irParaNotas() {
        this.$router.push({
            name: 'pedagogico-notas',
            params: { id: this.turmaAlunoId },
        });
    }
    async mounted() {
        this.loading = true;
        this.turmaAlunoId = Number(this.$route.params.id);
        this.items = await atHomeStore.getAtHomeNotasAtHome(this.turmaAlunoId);
        this.loading = false;
    }
};
Details = __decorate([
    Component({
        components: {
            ShowDialog,
            JaasMeet,
            UploadDownloadFileButton,
            DataTable,
        },
    })
], Details);
export default Details;

import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let DataTable = class DataTable extends Vue {
};
__decorate([
    Prop({ type: Boolean, default: false })
], DataTable.prototype, "hideDefaultFooter", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], DataTable.prototype, "fixedHeader", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], DataTable.prototype, "disablePagination", void 0);
__decorate([
    Prop({ type: Array, default: () => [] })
], DataTable.prototype, "propHeaders", void 0);
__decorate([
    Prop({ type: Array, default: () => [] })
], DataTable.prototype, "propItems", void 0);
__decorate([
    Prop({ type: Number, default: null })
], DataTable.prototype, "propHeight", void 0);
__decorate([
    Prop({ default: '' })
], DataTable.prototype, "propSearch", void 0);
DataTable = __decorate([
    Component
], DataTable);
export default DataTable;

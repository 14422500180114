import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { differenceInMinutes, isAfter } from 'date-fns';
import { atHomeStore, mainStore, turmaAlunoAtividadeStore, turmaStore, } from '@/store';
import ShowDialog from './ShowDialog.vue';
import { Validator } from 'vee-validate';
import { QrcodeStream } from 'vue-qrcode-reader';
Validator.extend('meetFormat', {
    getMessage: (field) => 'Formato do link Google Meet inválido. Siga o formato: https://meet.google.com/seu-codigo-meet',
    validate: async (v) => !/^https:\/\/meet\.google\.com\/[a-z\-]{8,}$/.test(v),
});
let JaasMeet = class JaasMeet extends Vue {
    constructor() {
        super(...arguments);
        this.aulaOnlineActive = false;
        this.aulas = [];
        this.aulaLink = '';
        this.clicked = false;
        this.qrCode = '';
        this.aulaEstaPermitida = false;
        this.loadingPesquisarQrCode = false;
        this.loadingScanQrCode = false;
        this.qrCodeScanned = false;
        this.turmaAtividadeIdSelecionada = 0;
        this.codigoProduto = '';
    }
    async onChange(val) {
        this.loadingPesquisarQrCode = true;
        const response = await atHomeStore.atHomeScannearQrCode({ qrCode: val, codigoProduto: this.codigoProduto });
        this.aulaEstaPermitida = response.aula_esta_permitida;
        this.loadingPesquisarQrCode = false;
        this.qrCodeScanned = true;
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    async startClass(turmaAtividadeId, show = true) {
        this.turmaAtividadeIdSelecionada = turmaAtividadeId;
        const refs = this.$refs.showCode;
        refs.dialog = show;
        // if (this.aulaLink) {
        //   window.open(this.aulaLink, '_blank').focus();
        // }
    }
    async registrarLink() {
        await this.atualizarLocalAula(true);
        this.aulas = await atHomeStore.getAtHomeTurmaAulaHoje(this.propTurmaId);
        this.aulaOnlineActive = true;
        if (this.propProfessorId && this.aulaLink != null) {
            await turmaStore.updateTurmaAtHomeIniciarAula({
                turmaId: this.propTurmaId,
                meetCode: this.extractMeetingCode(this.aulaLink),
            });
            this.$forceUpdate();
        }
    }
    async atualizarLink() {
        if (this.propProfessorId && this.aulaLink != null) {
            await turmaStore.updateTurmaAtHomeIniciarAula({
                turmaId: this.propTurmaId,
                meetCode: this.extractMeetingCode(this.aulaLink),
            });
        }
        this.handleShowDialog(false);
        this.$forceUpdate();
    }
    async atualizarLocalAula(isAtHome) {
        await turmaStore.updateTurmaLocalAula({ turmaId: this.propTurmaId, isAtHome });
        if (!isAtHome) {
            this.handleShowDialogUpdateLocal(false);
            location.reload();
        }
    }
    async participarAula() {
        await turmaAlunoAtividadeStore.turmaAlunoAtividadePresencaAluno({
            turmaAtividadeId: this.turmaAtividadeIdSelecionada,
        });
        window.open(this.aulaLink, '_blank').focus();
    }
    extractMeetingCode(meetLink) {
        const regex = /meet\.google\.com\/([^?]*)/;
        const match = meetLink.match(regex);
        return match ? match[1] : null;
    }
    async ativaTurmaDigital() {
        this.clicked = true;
    }
    clickAppend() {
        this.qrCode = '';
        this.qrCodeScanned = false;
        this.aulaEstaPermitida = false;
    }
    handleShowQrCode() {
        const refs = this.$refs.showQrCode;
        refs.dialog = true;
    }
    async handleScannearQrCode(value) {
        this.loadingScanQrCode = true;
        const response = await atHomeStore.atHomeScannearQrCode({ qrCode: value, codigoProduto: this.codigoProduto });
        this.aulaEstaPermitida = response.aula_esta_permitida;
        const refs = this.$refs.showQrCode;
        refs.close();
        this.qrCode = value;
        this.loadingScanQrCode = false;
    }
    formatHour(hour) {
        const [hours, minutes] = hour.split(':');
        const formattedHour = `${parseInt(hours, 10)}h${minutes}`;
        const addedHour = `${parseInt(hours, 10) + 1}h${minutes}`;
        const classDuration = formattedHour + ' - ' + addedHour;
        return classDuration;
    }
    isStarting(time) {
        const date = time.data_atividade + ' ' + time.hora_inicio_atividade;
        const startTime = new Date(date);
        const currentTime = new Date();
        const difference = differenceInMinutes(startTime, currentTime);
        return difference > 0 && difference <= 5;
    }
    isStarted(time) {
        const date = time.data_atividade + ' ' + time.hora_inicio_atividade;
        const startTime = new Date(date);
        const currentTime = new Date();
        const difference = differenceInMinutes(startTime, currentTime);
        return difference >= -30 && difference <= 0;
    }
    minutesUntilStart(item) {
        const date = item.data_atividade + ' ' + item.hora_inicio_atividade;
        const startTime = new Date(date);
        const currentTime = new Date();
        if (isAfter(currentTime, startTime)) {
            return null;
        }
        const intervalId = setInterval(() => {
            const updatedDifference = differenceInMinutes(startTime, new Date());
            if (updatedDifference <= 0) {
                clearInterval(intervalId);
                this.$forceUpdate();
            }
            else {
                this.$forceUpdate();
            }
        }, 60000);
        return Math.min(differenceInMinutes(startTime, currentTime), 5);
    }
    handleShowDialog(show = true) {
        this.clicked = false;
        const refs = this.$refs.showClass;
        refs.dialog = show;
    }
    handleBack() {
        const refs = this.$refs.showCode;
        refs.close();
    }
    handleShowDialogUpdateLocal(show = true) {
        const refs = this.$refs.showUpdateLocal;
        refs.dialog = show;
    }
    async copyToClipboard(content) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            await navigator.clipboard.writeText(content);
            mainStore.addNotification(loadingNotification);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Link da turma copiado!',
                color: 'success',
            });
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async mounted() {
        this.aulaOnlineActive = this.propAulaOnlineActive;
        if (this.propTurmaId) {
            this.aulas = await atHomeStore.getAtHomeTurmaAulaHoje(this.propTurmaId);
        }
        else if (this.propTurmaAlunoId) {
            this.aulas = await atHomeStore.getAtHomeTurmaAlunoAulaHoje(this.propTurmaAlunoId);
            this.codigoProduto = Object.values(this.aulas)[0][0].codigo_produto;
        }
        this.aulaLink = Object.values(this.aulas)[0][0].aula_online_url;
    }
};
__decorate([
    Prop({ type: Number, default: null })
], JaasMeet.prototype, "propTurmaId", void 0);
__decorate([
    Prop({ type: Number, default: null })
], JaasMeet.prototype, "propTurmaAlunoId", void 0);
__decorate([
    Prop({ type: Number, default: null })
], JaasMeet.prototype, "propProfessorId", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], JaasMeet.prototype, "propAulaOnlineActive", void 0);
__decorate([
    Watch('qrCode')
], JaasMeet.prototype, "onChange", null);
JaasMeet = __decorate([
    Component({ components: { ShowDialog, QrcodeStream } })
], JaasMeet);
export default JaasMeet;

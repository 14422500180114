import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import ShowDialog from '@/components/ShowDialog.vue';
import { turmaAlunoAtividadeStore } from '@/store';
let UploadDownloadFileButton = class UploadDownloadFileButton extends Vue {
    constructor() {
        super(...arguments);
        this.files = [];
        this.file = null;
        this.tipo = '';
        this.multiple = false;
        this.loadingSave = false;
        this.bucketArquivoAtividadeId = 0;
        this.bucketArquivoCorrecaoId = 0;
    }
    async changePropTipo(val) {
        this.tipo = val;
    }
    async changePropMultiple(val) {
        this.multiple = val;
    }
    async changePropBucketArquivoAtividadeId(val) {
        this.bucketArquivoAtividadeId = val;
    }
    async changePropBucketArquivoCorrecaoId(val) {
        this.bucketArquivoCorrecaoId = val;
    }
    async handleExcluirAtividade() {
        this.loadingSave = true;
        this.$swal({
            title: 'Excluir tarefa',
            html: 'Você tem certeza de que quer excluir a tarefa enviada? <br/> Você não poderá desfazer essa ação.',
            icon: 'info',
            iconColor: '#F70052',
            showCancelButton: true,
            cancelButtonText: 'CANCELAR',
            cancelButtonColor: 'transparent',
            confirmButtonText: 'SIM, TENHO CERTEZA',
            confirmButtonColor: '#645FC9',
            customClass: {
                title: 'my-title-class fira-bold',
                htmlContainer: 'my-html-container-class articulat-regular',
                popup: 'my-popup-class',
                confirmButton: 'my-confirm-button-class articulat-medium',
                cancelButton: 'my-cancel-button-class articulat-medium'
            },
        }).then(async (result) => {
            if (result.isConfirmed) {
                const response = await turmaAlunoAtividadeStore.turmaAlunoAtividadeExcluirTarefa({
                    turmaAlunoAtividade: this.propId,
                    bucketArquivoAtividadeId: this.bucketArquivoAtividadeId,
                });
                if (response) {
                    this.$swal({
                        title: 'Tarefa excluída com sucesso!',
                        icon: 'success',
                        confirmButtonColor: '#645FC9',
                        showConfirmButton: false,
                        timer: 2000,
                        customClass: {
                            title: 'my-title-class fira-bold',
                            popup: 'my-popup-class',
                            confirmButton: 'my-confirm-button-class articulat-medium',
                        },
                    });
                    this.bucketArquivoAtividadeId = 0;
                }
            }
        });
        this.loadingSave = false;
    }
    async handleDownloadAtividade() {
        const response = await turmaAlunoAtividadeStore.downloadAtividade({
            turmaAlunoAtividade: this.propId,
            bucketArquivoAtividadeId: this.bucketArquivoAtividadeId,
        });
        const element = document.createElement('a');
        element.setAttribute('href', response.download_url);
        if (response.blob.full_name.includes('pdf') ||
            response.blob.full_name.includes('png') ||
            response.blob.full_name.includes('jpg') ||
            response.blob.full_name.includes('jpeg')) {
            element.setAttribute('target', '_blank');
        }
        else {
            element.setAttribute('style', 'display:none;');
        }
        element.click();
        element.remove();
    }
    async handleDownloadCorrecao() {
        const response = await turmaAlunoAtividadeStore.downloadCorrecao({
            turmaAlunoAtividade: this.propId,
            bucketArquivoCorrecaoId: this.bucketArquivoCorrecaoId,
        });
        const element = document.createElement('a');
        element.setAttribute('href', response.download_url);
        if (response.blob.full_name.includes('pdf') ||
            response.blob.full_name.includes('png') ||
            response.blob.full_name.includes('jpg') ||
            response.blob.full_name.includes('jpeg')) {
            element.setAttribute('target', '_blank');
        }
        else {
            element.setAttribute('style', 'display:none;');
        }
        element.click();
        element.remove();
    }
    async handleSendArquivo() {
        let fileSize = 0;
        let fileSend = null;
        if (this.multiple) {
            fileSize = this.files[0].size;
            fileSend = this.files[0];
        }
        else {
            fileSend = this.file;
            fileSize = this.file.size;
        }
        const maximo5MB = 5 * 1024 * 1024;
        if (fileSize > maximo5MB) {
            this.$swal({
                title: 'Arquivo maior que o tamanho máximo permitido',
                text: 'O arquivo tem que ter no máximo 5MB!',
                icon: 'error',
            });
            return;
        }
        this.loadingSave = true;
        if (this.tipo.toLocaleLowerCase() === 'atividade'.toLocaleLowerCase()) {
            const response = await turmaAlunoAtividadeStore.enviaArquivoAtividade({
                id: this.propId,
                file: fileSend,
            });
            this.$swal({
                title: 'Tarefa enviada',
                html: 'O professor corrigirá sua prova em breve e você receberá <br/> feedback detalhado. Acompanhe por aqui.',
                icon: 'success',
                confirmButtonText: 'CONCLUIR',
                confirmButtonColor: '#645FC9',
                timer: 3500,
                customClass: {
                    title: 'my-title-class fira-bold',
                    htmlContainer: 'my-html-container-class articulat-regular',
                    popup: 'my-popup-class',
                    confirmButton: 'my-confirm-button-class my-confirm-button-width articulat-medium',
                },
            });
            this.bucketArquivoAtividadeId = response;
        }
        else if (this.tipo.toLowerCase() === 'correcao'.toLocaleLowerCase()) {
            const response = await turmaAlunoAtividadeStore.enviaArquivoCorrecao({
                id: this.propId,
                file: fileSend,
            });
            this.bucketArquivoCorrecaoId = response;
        }
        this.loadingSave = false;
        const refs = this.$refs.showFile;
        refs.dialog = false;
    }
    handleShowDialog(show = true) {
        const refs = this.$refs.showFile;
        refs.dialog = show;
    }
    async openInput() {
        const el = document.getElementById('fileInput');
        el.click();
    }
    handleCancelFile(remove) {
        if (this.multiple) {
            this.files = this.files.filter((file) => file !== remove);
        }
        else {
            this.file = null;
        }
    }
    handleCloseDialog() {
        const refs = this.$refs.showFile;
        refs.dialog = false;
    }
};
__decorate([
    Prop(String)
], UploadDownloadFileButton.prototype, "color", void 0);
__decorate([
    Prop({ type: Number })
], UploadDownloadFileButton.prototype, "propId", void 0);
__decorate([
    Prop({ type: Number })
], UploadDownloadFileButton.prototype, "propBucketArquivoAtividadeId", void 0);
__decorate([
    Prop({ type: Number })
], UploadDownloadFileButton.prototype, "propBucketArquivoCorrecaoId", void 0);
__decorate([
    Prop({ type: String, required: true })
], UploadDownloadFileButton.prototype, "propTipo", void 0);
__decorate([
    Prop({ default: false })
], UploadDownloadFileButton.prototype, "propMultiple", void 0);
__decorate([
    Prop({ type: Array, default: () => [] })
], UploadDownloadFileButton.prototype, "propFunctionSave", void 0);
__decorate([
    Prop({ type: String, required: true })
], UploadDownloadFileButton.prototype, "propTipoAcesso", void 0);
__decorate([
    Prop({ type: String, default: 'primary' })
], UploadDownloadFileButton.prototype, "propColor", void 0);
__decorate([
    Prop({ type: String, default: '' })
], UploadDownloadFileButton.prototype, "propStyle", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], UploadDownloadFileButton.prototype, "propBlock", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], UploadDownloadFileButton.prototype, "propNoGutters", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], UploadDownloadFileButton.prototype, "propArquivoAtividadeId", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], UploadDownloadFileButton.prototype, "propArquivoCorrecaoId", void 0);
__decorate([
    Watch('propTipo', { immediate: true })
], UploadDownloadFileButton.prototype, "changePropTipo", null);
__decorate([
    Watch('propMultiple', { immediate: true })
], UploadDownloadFileButton.prototype, "changePropMultiple", null);
__decorate([
    Watch('propBucketArquivoAtividadeId', { immediate: true })
], UploadDownloadFileButton.prototype, "changePropBucketArquivoAtividadeId", null);
__decorate([
    Watch('propBucketArquivoCorrecaoId', { immediate: true })
], UploadDownloadFileButton.prototype, "changePropBucketArquivoCorrecaoId", null);
UploadDownloadFileButton = __decorate([
    Component({ components: { ShowDialog } })
], UploadDownloadFileButton);
export default UploadDownloadFileButton;
